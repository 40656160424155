<template>
  <v-chip
    :color="stringColor(bracket, 0.2)"
    v-bind="$attrs"
    class="text-capitalize px-2 mr-1"
    x-small
    label
  >
    <v-icon
      v-if="icon"
      class="mr-1"
      x-small
    >
      {{ $age_brackets.find(b => b.name === bracket).icon }}
    </v-icon>
    {{ bracket }}
    <span v-if="age">: {{ age }}</span>
  </v-chip>
</template>
<script>
export default {
  props: {
    bracket: {
      type: String,
      required: true,
    },
    age: {
      type: Number,
      required: true,
    },
    icon: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
